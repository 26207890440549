import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";
const SEO = ({ title, description, image, article, keywords }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    defaultKeywords,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata;
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
    keywords: keywords || defaultKeywords,
  };
  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta charset="UTF-8"/>
      <meta name="google-site-verification" content="B9YT3qcIUVKHoohAA97CdUa0iKVZ4WOOhrs1Y_8oDOs" />
      <meta name="google-site-verification" content="Q4pBcJXyJ-4my54_4uJkWm8Iml52KkWBjRyaAPka5oM" />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta
        name="keywords"
        content={
          seo.keywords.length > 0
            ? {
                name: `keywords`,
                content: seo.keywords.join(`, `),
              }
            : []
        }
      />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      <script type="application/ld+json">
      {`
      {
        "@context": "http://schema.org",
        "@type": "LocalBusiness",
        "@id": "https://kedarastudios.com/?empresa",
        "url": "https://kedarastudios.com/",
        "name": "KEDARA Studios - Diseño y Desarollo Web",
        "image": "https://kedarastudios.com/favicons/og-image.jpg",
        "logo": "https://kedarastudios.com/logo/KEDARA-Studios-horizontal.png",            
        "priceRange": "$$$",
        "description": "Kedara Studios es una empresa dedicada al desarrollo y diseño web, aplicaciones móviles y hacking ético. Creamos productos digitales desde la concepción hasta su salida al mercado, acompañando a empresas y startups en su crecimiento y logrando la consecución de sus objetivos.",
        "sameAs": [ 
          "https://www.facebook.com/kedarastudios/",
          "https://play.google.com/store/apps/dev?id=7693008840031300308&hl=es_ES&gl=ES",
          "https://g.page/kedarastudios/",
          "https://www.linkedin.com/company/kedara-studios/",
          "https://twitter.com/kedarastudios"
          ],
          "aggregateRating":{"@type":"AggregateRating","ratingValue":"5.0","reviewCount":"14"},
                  "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Calle Eucaliptus, 55, 2º",
                    "addressLocality": "Málaga",
                    "addressRegion": "Málaga",
                    "postalCode": "29018",
                    "addressCountry": "ES"
                  },
                  "contactPoint": {
                  "@type": "ContactPoint",
                  "contactType": "customer support",
                  "telephone": "[+34675142310]",
                  "areaServed" : "ES",
                  "email": "contacto@kedarastudios.com"
                  },
                  "openingHours": "Mo,Tu,We,Th,Fr 09:00-18:00",
                  "openingHoursSpecification": [ {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday"
          ],
          "opens": "09:00",
          "closes": "18:00"
          } ],
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": 36.730724,
          "longitude": -4.370947
        },
        "telephone": "+34675142310",
        "email": "contacto@kedarastudios.com"
      }
      `}
      </script>
    </Helmet>
  );
};
export default SEO;
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  article: PropTypes.bool,
};
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  keywords: [],
  article: false,
};
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
        defaultKeywords: keywords
      }
    }
  }
`;
