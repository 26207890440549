import React, { Component } from "react";
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import logo from "../../images/logo-horizontal.svg";

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems : [
        { id: 1 , idnm : "inicio", title: "Inicio" },
        { id: 2 , idnm : "servicios", title: "Servicios"},
        //{ id: 3 , idnm : "proceso", title: "Proceso"},
        //{ id: 4 , idnm : "works", title: "Proyectos" },
        { id: 5 , idnm : "clientes", title: "Clientes" },
        { id: 6 , idnm : "contacto", title: "Contacto" },
        ],
        isOpen : false, 
    };
    this.toggleLine = this.toggleLine.bind(this);
  }

  toggleLine() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }
  
  render() {
    
    return (
      <>
        <header id="topnav" className="defaultscroll sticky">
          <div className="container">
            <div>
              <Link className="logo" to="/">
                <img
                  src={logo}
                  className="img-fluid"
                  alt="Kedara Studios Logo"
                />
              </Link>
            </div>
            <div className="buy-button">
            <a href="tel:+34675142310" className="mailgo btn btn-primary">
              Llamar
            </a>
            </div>
            <div className="menu-extras">
              <div className="menu-item">
                <button
                  onClick={this.toggleLine}
                  className={
                    this.state.isOpen ? "navbar-toggle open" : "navbar-toggle"
                  }
                >
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </div>
            </div>

            <div
              id="navigation"
              style={{ display: this.state.isOpen ? "block" : "none" }}
            >
              <ul className="navigation-menu" id="top-menu">
                {this.state.navItems.map((item, key) => (
                  <li key={key}>
                  <AnchorLink onAnchorLinkClick={this.toggleLine} to={"/#"+item.idnm}>{item.title}</AnchorLink>
                  </li>
                  ))}
              </ul>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default Topbar;
