import React, { Component } from "react";
import Link from "../Link";
import { Row, Col } from "reactstrap";
import logo from "../../images/logo-vertical.svg";
class FooterLight extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <footer className="footer bg-light">
          <div className="container">
            <Row>
              <Col lg={4} className="col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                <Link className="logo-footer text-dark" to="/">
                  <img src={logo} alt="Kedara Studios" />
                </Link>
                {/* <p className="mt-4 text-muted">
                  Start working with Landrick that can provide everything you
                  need to generate awareness, drive traffic, connect.
                </p> */}
                <ul className="list-unstyled social-icon social mb-0 mt-4">
                  <li className="list-inline-item">
                    <Link to="https://www.facebook.com/kedarastudios" className="rounded mr-1">
                      <i className="mdi mdi-facebook" title="Facebook"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="https://www.linkedin.com/company/kedara-studios" className="rounded">
                      <i className="mdi mdi-linkedin" title="Linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </Col>

              <Col lg={4} md={4} className="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h4 className="text-dark footer-head">Legal</h4>
                <ul className="list-unstyled footer-list mt-4">
                  <li>
                    <Link to="/aviso-legal" rel="noindex nofollow" className="text-muted">
                      <i className="mdi mdi-chevron-right mr-1"></i> Aviso Legal
                    </Link>
                  </li>

                  <li>
                    <Link to="/politica-de-privacidad" rel="noindex nofollow" className="text-muted">
                      <i className="mdi mdi-chevron-right mr-1"></i> Política de
                      Privacidad
                    </Link>
                  </li>
                </ul>
              </Col>

              <Col lg={4} md={4} className="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              
              </Col>
            </Row>
          </div>
        </footer>
        <hr />
        <footer className="footer footer-bar">
          <div className="container text-center">
            <Row className="align-items-center">
              <Col sm={6}>
                <div className="text-sm-left">
                  <p className="mb-0">
                    © {new Date().getFullYear()} Kedara Studios. All rights
                    reserved.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default FooterLight;
