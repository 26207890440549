import React from "react";
import PropTypes from "prop-types";

// Layout Components
import Topbar from "./Topbar";

import FooterLight from "./FooterLight";
// Scroll up button
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
// Import Css
import "../../Apps.scss";
//import "../../css/materialdesignicons.min.css";
const Layout = ({ children}) => (
  <>
  
    <Topbar/>
    {children}
    {(() => {
      return <FooterLight />;
    })()}
    <div id="bottomIcon">
      <ScrollUpButton ContainerClassName="back-to-top rounded text-center" />
    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Layout;
